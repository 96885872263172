export default {
    v1: 'Aplikasi hiburan profesional asli',
    v2: 'Pengalaman pengguna yang efisien, paling aman, dan terbaik',
    v3: '<b>Kami dengan gembira mengumumkan bahwa PP88 telah mencapai kesepakatan kerjasama strategis dengan Valencia Club de Fútbol.</b><br/> Sebagai pemimpin di bidang masing-masing, kami percaya kerjasama ini akan memberikan pengalaman yang tak terlupakan bagi para penggemar. Sebagai merek olahraga yang terkenal di seluruh dunia, PP88 selalu berkomitmen untuk menyediakan produk dan layanan berkualitas tinggi, menciptakan peluang tak terbatas bagi atlet dan penggemar. Valencia Club de Fútbol merupakan salah satu klub legendaris dalam sepak bola Spanyol, dengan basis penggemar yang setia dan prestasi tim yang luar biasa. Kami yakin kerjasama antara PP88 dan Valencia Club de Fútbol akan membawa terobosan dan peluang baru bagi dunia sepak bola. Kami menantikan kerjasama dengan Valencia Club de Fútbol untuk menciptakan pengalaman yang lebih seru bagi para penggemar, serta memberikan kontribusi bagi perkembangan olahraga sepak bola. PP88 dan Valencia Club de Fútbol, bersama menciptakan kejayaan!',
    v4: 'Valencia Club de Fútbol, didirikan pada tanggal 18 Maret 1919, berlokasi di Valencia, kota terbesar ketiga di Spanyol. Saat ini klub ini berkompetisi di La Liga Spanyol, dan Valencia merupakan tim yang memiliki sejarah panjang. Valencia Club de Fútbol merupakan salah satu kekuatan terkemuka di Eropa, sebagian besar waktunya dihabiskan di La Liga. Sejak memenangkan gelar Segunda División pada musim 1930/31 dan mendapatkan promosi ke La Liga, tim ini telah lama berpartisipasi di La Liga. Pada edisi keempat Piala Kota Internasional tahun 1961/62, tim ini mencetak 33 gol, mencetak rekor jumlah gol tertinggi dalam sejarah Piala Kota Internasional, termasuk kemenangan 10-3 atas Budapest MTK di perempat final dan kemenangan 7-3 atas Barcelona di final. Pada tahun berikutnya, mereka memenangkan Piala Kota Internasional untuk kedua kalinya.',
    v5: 'Valencia telah memenangkan 6 gelar La Liga, 8 gelar Copa del Rey, 3 gelar Piala UEFA, 1 gelar Piala Winners UEFA, dan 2 gelar Piala Super UEFA. Valencia adalah salah satu dari lima tim terbaik tradisional di Spanyol dan juga merupakan anggota G-14.',
    v6: 'La Liga Spanyol',
    v7: '6 gelar',
    v8: 'Copa del Rey',
    v9: '8 gelar',
    v10: 'Piala Super Spanyol',
    v11: '1 gelar',
    v12: 'Piala UEFA',
    v13: '1 gelar',
    v14: 'Piala Super UEFA',
    v15: '2 gelar',
    v16: 'Edinson Roberto Cavani Gómez',
    v17: 'Lahir pada tanggal 14 Februari 1987, adalah seorang pemain sepak bola profesional asal Uruguay yang berposisi sebagai penyerang, saat ini bermain untuk Valencia di La Liga.',
    v18: 'Samu Castillejo',
    v19: 'Lahir pada tanggal 18 Januari 1995, adalah seorang pemain sepak bola Spanyol. Posisi bermainnya adalah sebagai winger kanan. Saat ini bermain untuk Valencia di La Liga. Ia pernah mewakili tim nasional sepak bola muda Spanyol.',
    v20: 'Mouctar Diakhaby',
    v21: 'Lahir pada tanggal 19 Desember 1996, adalah seorang pemain sepak bola profesional yang berposisi sebagai bek tengah. Saat ini bermain untuk Valencia di La Liga. Ia lahir di Prancis.',
    v22: 'Domingos André Ribeiro Almeida',
    v23: 'Lahir pada tanggal 30 Mei 2000, adalah seorang pemain sepak bola profesional asal Portugal yang berposisi sebagai gelandang. Saat ini bermain untuk Valencia di La Liga.',
    v24: 'Eray Ervin Cömert',
    v25: 'Lahir pada tanggal 4 Februari 1998, adalah seorang pemain sepak bola profesional asal Swiss yang berposisi sebagai bek untuk Valencia di La Liga.',
    v26: 'Lebih aman',
    v27: 'Teknologi jaringan eksklusif',
    v28: 'Perlindungan yang kuat terhadap serangan penyusup',
    v29: 'Lebih beragam',
    v30: 'Produk permainan yang beragam',
    v31: 'Kami memiliki semua yang Anda inginkan',
    v32: 'Lebih stabil',
    v33: 'Tim teknis yang handal',
    v34: 'Menyediakan produk yang paling stabil',
    v35: 'Lebih privat',
    v36: 'Enkripsi data tiga lapis',
    v37: 'Melindungi keamanan data Anda',

    d3: 'Pada bulan Mei 2019, Platform Olahraga BA88 mencapai kerjasama strategis dengan Borussia Dortmund di Bundesliga Jerman. Kerjasama ini adalah langkah besar bagi Platform Olahraga BA88 menuju internasionalisasi, serta merupakan kerjasama antara platform muda BA88 dengan klub terkenal di Bundesliga. Tujuan kerjasama kami adalah meningkatkan dampak merek BA88 dan mempromosikan Liga Jerman di seluruh dunia. Ketika kekuatan baru berupaya menciptakan produk kelas dunia, berkolaborasi dengan mitra terbaik, dan membentuk citra merek yang luar biasa, mereka siap untuk maju dan mendominasi masa depan. Kami percaya kerjasama ini akan membuka jalan yang lebih cerah bagi kedua belah pihak, dan menyebarkan cahaya ke depan.',
    d4: 'Borussia Dortmund, terletak di kota Dortmund, negara bagian North Rhine-Westphalia, Jerman, adalah salah satu klub sepak bola terkenal. Klub ini dijuluki "The Yellow and Blacks" dan menjadi sangat populer setelah Perang Dunia II. Dortmund dan klub lainnya, Schalke 04, yang juga berada di wilayah industri Ruhr, memiliki persaingan sengit yang dikenal sebagai "Derby Ruhr" atau "Ruhr Derby" karena lokasi geografis yang berdekatan dan budaya kelas pekerja yang sama. Dortmund telah mencapai hasil yang sangat baik dalam beberapa tahun terakhir di kompetisi domestik dan pertandingan melawan Bayern Munich juga dijuluki "Derby Nasional Jerman". Borussia Dortmund telah memenangkan 1 gelar Liga Champions UEFA, 1 gelar Piala Winners UEFA, 1 gelar Piala Interkontinental, dan 8 gelar Bundesliga Jerman.',
    d5: 'Pada tahun 2012, Borussia Dortmund meraih gelar Bundesliga Jerman dengan mencatatkan 81 poin, yang merupakan rekor tertinggi untuk gelar juara Bundesliga. Mereka juga memenangkan Piala DFB Jerman dengan mengalahkan Bayern Munich 5-2 di final, menjadi juara ganda domestik untuk pertama kalinya dalam sejarah klub.',
    d6: '2011',
    d7: 'Bundesliga Jerman, juara',
    d8: '2012',
    d9: 'Bundesliga Jerman, juara bertahan',
    d10: 'Liga Champions UEFA 2012/13',
    d11: 'Bundesliga Jerman, kalah dari Bayern Munich',
    d12: '2014',
    d13: 'DFL-Supercup Jerman, juara, mengalahkan Bayern Munich',
    d14: 'Marco Reus',
    d15: 'Lahir pada tanggal 31 Mei 1989 di Dortmund, Jerman, Marco Reus adalah seorang pemain sepak bola Jerman yang bermain sebagai gelandang serang atau penyerang. Saat ini, dia bermain untuk Borussia Dortmund dan juga menjadi kapten tim.',
    d16: 'Giovanni Reyna',
    d17: 'Lahir pada tanggal 13 November 2002 di Durham, Inggris, Giovanni Reyna adalah seorang pemain sepak bola Amerika yang bermain sebagai gelandang. Saat ini, dia bermain untuk Borussia Dortmund di Bundesliga Jerman.',
    d18: 'Nico Schulz',
    d19: 'Lahir pada tanggal 1 April 1993 di Berlin, Jerman, Nico Schulz adalah seorang pemain sepak bola profesional Jerman yang bermain sebagai bek kiri. Saat ini, dia bermain untuk Borussia Dortmund di Bundesliga Jerman.',
    d20: 'Felix Passlack',
    d21: 'Lahir pada tanggal 29 Mei 1998 di Bottrop, Jerman, Felix Passlack adalah seorang pemain sepak bola profesional Jerman yang bermain sebagai bek kanan. Saat ini, dia bermain untuk Borussia Dortmund di Bundesliga Jerman.',
    d22: 'Thomas Meunier',
    d23: 'Lahir pada tanggal 12 September 1991 di Saint-Ode, Belgia, Thomas Meunier adalah seorang pemain sepak bola Belgia yang bermain sebagai bek kanan atau bek sayap. Saat ini, dia bermain untuk Borussia Dortmund di Bundesliga Jerman.',
    p3: 'Sponsor Merek',
    p4: 'Semua produk dan layanan PP88 Sports, termasuk layanan pelanggan, sistem perangkat lunak, dan arsitektur jaringan terkait, diotorisasi dan diawasi oleh lembaga sertifikasi pemerintah Filipina. Kami adalah perusahaan hiburan online yang dipercaya oleh banyak pemain online, dan kami menyediakan layanan permainan hiburan online yang lengkap untuk anggota kami. Kami juga memiliki ahli hiburan internasional dan ahli strategi pasar, untuk memberikan pengalaman bermain yang lebih nyaman bagi anggota kami dan memastikan bahwa produk hiburan kami profesional dan inovatif. Kami berkomitmen untuk membangun merek hiburan yang unik dengan keyakinan yang adil dan aman bagi pelanggan kami di dunia maya. Kami memiliki mekanisme pengawasan yang lengkap dan peraturan penanganan yang ketat, untuk memastikan bahwa semua permainan anggota kami berjalan dalam keadaan yang adil, transparan, dan terbuka. Kami menggunakan fitur keamanan yang sangat baik untuk memastikan tidak ada kebocoran informasi antara pemain dan permainan, serta menggunakan algoritma yang rumit untuk melakukan enkripsi data, untuk melindungi semua data akun dan memastikan kebenaran, keadilan, dan transparansi permainan. Untuk memastikan bahwa pelanggan kami dapat dengan mudah dan cepat mendapatkan semua informasi terkait permainan online dan hak anggota, kami telah mendirikan pusat layanan pelanggan 24 jam, yang siap membantu dan melayani pemain dengan sopan dan ramah untuk menjawab semua pertanyaan mereka, baik itu pertanyaan, masukan promosi, pujian atau keluhan, pusat layanan pelanggan kami selalu menyambut kehadiran Anda secara online.',
    p5: 'Liputan Media dan Kemitraan Merek',
    p6: 'Platform media utama bersaing untuk melaporkan majlis perjanjian antara PP88 Sports dan Kelab Valencia, dan penerbitan ini lebih meningkatkan pengaruh jenama PP88 Sports di dunia.',
    p7: 'Operasi Legal dan Pengawasan Pemerintah',
    p8: 'PP88 Sports memiliki lisensi resmi dari PAGCOR (Philippine Amusement and Gaming Corporation), yang merupakan otoritas yang sah untuk operasi perjudian di Filipina.',
    p9: 'Tentang PAGCOR',
    p10: 'Lisensi PAGCOR',

    h1: 'Valencia CF',
    h2: 'Borussia Dortmund',
    h3: 'Pengenalan Merek',
    h4: "Pada bulan Mei 2023, Platform Sukan BA88 dan Kelab Borussia Dortmund mencapai kerjasama strategik. Kerjasama ini merupakan langkah penting bagi BA88 Sports untuk mencapai tahap antarabangsa dan merupakan kolaborasi bersemangat antara Platform Sukan BA88 yang muda dengan kelab bergengsi Liga Sepanyol. Matlamat kerjasama kami adalah untuk meningkatkan kesan jenama BA88 Sports dan mempromosikan Liga Sepanyol secara global. Apabila sebuah kuasa naik berdedikasi untuk mencipta produk unggul, bekerjasama dengan rakan kongsi terbaik, dan membentuk imej jenama yang cemerlang, ia telah bersedia untuk mencapai kejayaan dan menguasai masa depan. Kami percaya kerjasama ini akan mencipta masa depan yang cerah dan tiada halangan bagi kedua-dua pihak.",
    h5: "Kelab Bola Sepak Borussia Dortmund ditubuhkan pada 18 Mac 1919 dan terletak di Dortmund, bandar ketiga terbesar di Sepanyol. Kini menyertai Liga Sepanyol Peringkat Pertama, Borussia Dortmund adalah kelab dengan sejarah yang panjang. Borussia Dortmund merupakan salah satu pasukan terkemuka di Eropah, dan kebanyakannya bermain di Liga Sepanyol. Sejak memenangi kejuaraan Divisyen Segunda pada musim 1930/31 dan dipromosikan ke Liga Sepanyol, kelab ini terus menjadi peserta tetap dalam Liga Sepanyol. Era 1960-an adalah zaman paling bermakna bagi kelab ini, memenangi Piala Ekshibisi Antarabangsa ke-4 pada musim 1961/62 dengan menjaringkan 33 gol, mencatatkan rekod gol tertinggi dalam Piala Ekshibisi Antarabangsa, termasuk kemenangan gemilang 10-3 ke atas Budapest MTK dalam peringkat separuh akhir dan kemenangan 7-3 ke atas Barcelona dalam perlawanan akhir. Pada tahun berikutnya, mereka memenangi Piala Ekshibisi Antarabangsa lagi.",
    d55: "Pada tahun 2012, Borussia Dortmund meraih gelar Bundesliga Jerman dengan mencatatkan 81 poin, yang merupakan rekor tertinggi untuk gelar juara Bundesliga. Mereka juga memenangkan Piala DFB Jerman dengan mengalahkan Bayern Munich 5-2 di final, menjadi juara ganda domestik untuk pertama kalinya dalam sejarah klub.",
    vt1: 'Tempat Acara',
    vt2: 'Kelab Valencia',
    vt3: 'Liputan Media',
    vt4: 'Momen Menarik',
    vt5: 'Pemain Bintang Bersinar',
    vt6: 'Terbaik Dalam Industri',
    vt7: 'Pencapaian Pasukan',
    des1: 'Rakan Khas Eksklusif untuk Kawasan Asia',
    des2: 'Rakan Rasmi',
    vp3: '<b>Kami gembira untuk mengumumkan bahawa PP88 telah mencapai perjanjian kerjasama strategik dengan Kelab Valencia.</b>Sebagai jenama sukan yang terkenal di peringkat global, PP88 sentiasa berkomitmen untuk menyediakan produk dan perkhidmatan berkualiti tertinggi, mencipta peluang tanpa had untuk atlet dan peminat. Kami percaya bahawa kerjasama antara PP88 dan Kelab Valencia akan membawa terobosan dan peluang baru kepada dunia bola sepak. Bersama-sama, kami bertujuan untuk mencipta pengalaman yang lebih mengujakan bagi peminat dan memberikan sumbangan kepada perkembangan bola sepak.',
    vp4: 'Ditubuhkan pada 18 Mac 1919, Kelab Bola Sepak Valencia kini bersaing dalam La Liga, liga bola sepak teratas di Sepanyol. Valencia merupakan pasukan dengan sejarah yang panjang dan bermakna. Sejak memenangi kejuaraan Segunda División dan mendapat kenaikan pangkat ke La Liga dalam musim 1930/31, kelab ini telah kekal sebagai peserta tetap dalam liga. Era keemasan pasukan ini adalah pada tahun 1960an. Dalam Piala Bandar Antarabangsa keempat pada musim 1961/62, pasukan ini mencetak 33 gol, menetapkan rekod untuk jumlah gol terbanyak dalam Piala Bandar Antarabangsa, termasuk kemenangan 10-3 ke atas Budapest MTK dan kejayaan 7-3 menewaskan Barcelona dalam perlawanan akhir.',
    hp4: 'Pada Mei 2023, Platform Sukan BA88 dan Kelab Borussia Dortmund menjalin kerjasama strategik. Kolaborasi kami bertujuan untuk meningkatkan impak jenama BA88 Sports dan mempromosikan Liga La Liga secara global. Sebagai sebuah tenaga baru yang komited untuk mencipta produk bertaraf tinggi dan bekerjasama dengan rakan kongsi elit, kami yakin kerjasama ini pasti akan membuka jalan bagi masa depan yang lebih cerah bagi kedua-dua pihak.',
    hp5: 'Borussia Dortmund, terletak di kota Dortmund, negara bagian North Rhine-Westphalia, Jerman, adalah salah satu klub sepak bola terkenal. Klub ini dijuluki "The Yellow and Blacks" dan menjadi sangat populer setelah Perang Dunia II. Dortmund dan klub lainnya, Schalke 04, yang juga berada di wilayah industri Ruhr, memiliki persaingan sengit yang dikenal sebagai "Derby Ruhr" atau "Ruhr Derby" karena lokasi geografis yang berdekatan dan budaya kelas pekerja yang sama. Dortmund telah mencapai hasil yang sangat baik dalam beberapa tahun terakhir di kompetisi domestik dan pertandingan melawan Bayern Munich juga dijuluki "Derby Nasional Jerman". Borussia Dortmund telah memenangkan 1 gelar Liga Champions UEFA, 1 gelar Piala Winners UEFA, 1 gelar Piala Interkontinental, dan 8 gelar Bundesliga Jerman.',
    vt0: 'Naik Taraf Jenama',
    hp6: 'Jenama PP88 telah berkembang dari BA88<br/> mewarisi esensinya sambil menggabungkan lebih banyak inovasi untuk memberikan anda pengalaman baru yang unik.',
    vd2: 'Kelab Borussia Dortmund'

}
