export default {
    v1: 'Professional native entertainment app',
    v2: 'Efficient, safest, and ultimate user experience',
    v3: '<b>We are delighted to announce that PP88 has reached a strategic partnership agreement with Valencia Club de Fútbol.</b><br/> As leaders in their respective fields, we believe this collaboration will bring fans an unforgettable experience. As a globally renowned sports brand, PP88 has always been committed to providing the highest quality products and services, creating endless possibilities for athletes and fans. Valencia Club de Fútbol is one of the legendary clubs in Spanish football, with a loyal fan base and outstanding team achievements. We believe that the collaboration between PP88 and Valencia Club de Fútbol will bring new breakthroughs and opportunities to the world of football. We look forward to working with Valencia Club de Fútbol to create an even more exciting experience for fans and contribute our strength to the development of football. PP88 and Valencia Club de Fútbol, creating brilliance together!',
    v4: 'Valencia Club de Fútbol, founded on March 18, 1919, is located in Valencia, the third-largest city in Spain. The club currently competes in the Spanish La Liga, and Valencia is a well-established team with a long history. Valencia Club de Fútbol is one of the renowned powerhouses in Europe, spending most of its time in La Liga. Since winning the Segunda División championship in the 1930/31 season and earning promotion to La Liga, the team has participated in La Liga for a long time. In the 1961/62 edition of the fourth International City Cup, the team scored 33 goals, setting the record for the highest number of goals in the International City Cup, including an 10-3 victory over Budapest MTK in the quarter-finals and a 7-3 victory over Barcelona in the final. The following year, they won the International City Cup again.',
    v5: 'Valencia has won 6 La Liga titles, 8 Copa del Rey titles, 3 UEFA Cup titles, 1 UEFA Cup Winners\' Cup, and 2 UEFA Super Cups. Valencia is one of the traditional top five teams in Spain and is also a member of the G-14.',
    v6: 'Spanish La Liga',
    v7: '6 titles',
    v8: 'Copa del Rey',
    v9: '8 titles',
    v10: 'Spanish Super Cup',
    v11: '1 title',
    v12: 'UEFA Cup',
    v13: '1 title',
    v14: 'UEFA Super Cup',
    v15: '2 titles',
    v16: 'Edinson Roberto Cavani Gómez',
    v17: 'Born on February 14, 1987, is a Uruguayan professional footballer who plays as a forward. He currently plays for Valencia in La Liga.',
    v18: 'Samu Castillejo',
    v19: 'Born on January 18, 1995, is a Spanish football player who plays as a right winger. He currently plays for Valencia in La Liga. He has represented the Spanish national youth football team.',
    v20: 'Mouctar Diakhaby',
    v21: 'Born on December 19, 1996, is a professional footballer who plays as a center-back. He currently plays for Valencia in La Liga. He was born in France.',
    v22: 'Domingos André Ribeiro Almeida',
    v23: 'Born on May 30, 2000, is a Portuguese professional footballer who plays as a midfielder. He currently plays for Valencia in La Liga.',
    v24: 'Eray Ervin Cömert',
    v25: 'Born on February 4, 1998, is a Swiss professional footballer who plays as a defender for Valencia in La Liga.',
    v26: 'Safer',
    v27: 'Exclusive network technology',
    v28: 'Strong defense against hijacking',
    v29: 'Richer',
    v30: 'Wide range of game products',
    v31: 'We have everything you want',
    v32: 'More stable',
    v33: 'Powerful technical team',
    v34: 'Provides the most stable products',
    v35: 'More private',
    v36: 'Triple data encryption',
    v37: 'Protects your data security',

    d3: 'In May 2019, BA88 Sports Platform reached a strategic cooperation with Borussia Dortmund in the German Bundesliga. This cooperation is a significant step for BA88 Sports Platform towards internationalization and an exciting collaboration between a young platform like BA88 Sports and an established German football club. Our goal is to enhance the brand impact of BA88 Sports and promote the Bundesliga worldwide. As an emerging force dedicated to creating top-quality products, collaborating with top partners, and shaping outstanding brand image, BA88 Sports is ready to rise and dominate the future. We believe that this cooperation between the two sides will create a bright and unobstructed future for each other. BA88 Sports and Borussia Dortmund, together for glory!',
    d4: 'Borussia Dortmund is a famous football club located in Dortmund, North Rhine-Westphalia, Germany. They are nicknamed the "Yellow and Blacks" ("Die Schwarzgelben"). The matches between Borussia Dortmund, which rose after World War II, and FC Schalke 04, located in the Ruhr industrial region (Ruhrgebiet), are extremely fierce due to their close geographical proximity and the same working-class culture. It is known as the "Ruhr Derby" or "Miner\'s Derby." The team has had excellent performance domestically in recent years and the matches against Bayern Munich are also known as the "German National Derby" by many fans. Borussia Dortmund has won 1 UEFA Champions League title, 1 UEFA Cup Winners\' Cup, 1 Intercontinental Cup, and 8 German Bundesliga titles.',
    d5: 'In 2012, Borussia Dortmund won the Bundesliga title again, achieving a record 81 points, the highest ever in Bundesliga history. They also won the DFB-Pokal final by defeating Bayern Munich 5-2, becoming the domestic double champions for the first time in the club\'s history.',
    d6: '2011',
    d7: 'German Bundesliga champions',
    d8: '2012',
    d9: 'Bundesliga (successful defense)',
    d10: '2012/13',
    d11: 'German Bundesliga runners-up (defeated by Bayern Munich)',
    d12: '2014',
    d13: 'DFL-Supercup winners (defeated Bayern Munich)',
    d14: 'Marco Reus',
    d15: 'Born on May 31, 1989, in Dortmund, Germany, Marco Reus is a German footballer who plays as a midfielder/forward for Borussia Dortmund in the Bundesliga. He is also the team captain.',
    d16: 'Giovanni Reyna',
    d17: 'Born on November 13, 2002, in Durham, England, Giovanni Reyna is an American footballer who plays as a midfielder for Borussia Dortmund in the Bundesliga.',
    d18: 'Nico Schulz',
    d19: 'Born on April 1, 1993, in Berlin, Germany, Nico Schulz is a German professional footballer who plays as a left-back. He currently plays for Borussia Dortmund in the Bundesliga.',
    d20: 'Felix Passlack',
    d21: 'Born on May 29, 1998, in Bottrop, Germany, Felix Passlack is a German professional footballer who plays as a right-back. He currently plays for Borussia Dortmund in the Bundesliga.',
    d22: 'Thomas Meunier',
    d23: 'Born on September 12, 1991, in Saint-Ode, Belgium, Thomas Meunier is a Belgian footballer who plays as a right-back/wing-back. He plays for Borussia Dortmund in the Bundesliga.',
    p3: 'Brand sponsorship',
    p4: 'All of our PP88 Sports products, customer service, software systems, network architecture, and related business matters are authorized and regulated by the Philippine government certification agency. We are an online entertainment company trusted by a large number of online players. We provide comprehensive online entertainment game services to our vast membership. We also have international entertainment experts and market strategy experts to make game usage more convenient for our members and to professionalize entertainment projects. We adhere to the belief of fairness and safety in creating a unique entertainment brand for customers online. We have a complete supervision mechanism and strict handling regulations to ensure that all members\' gaming entertainment is conducted in a fair, just, and transparent manner. We use excellent security technology to ensure flawless information transmission between players and games, using complex algorithms for data encryption to protect all player account data and ensure the correctness, fairness, and justice of the game structure. To provide our valued customers with the most convenient and fastest access to all relevant questions about online games and membership rights, we have specially established a 24-hour customer service center. Our customer service center welcomes you to come online at any time with the friendliest and most courteous service purpose to answer all your questions, whether they are inquiries, promotional opinions, compliments, or complaints.',
    p5: 'Media coverage and brand partnership',
    p6: 'Major media platforms are competing to report on the signing ceremony between PP88 Sports and Valencia Club, and these publications further enhance PP88 Sports\' brand influence in the world.',
    p7: 'Legitimate operation and government regulation',
    p8: 'About PAGCOR',
    p9: 'Philippine Amusement and Gaming Corporation (PAGCOR) is the authoritative organization that grants legal operating rights in the Philippines.',
    p10: 'PAGCOR certificate',

    h1: 'Valencia CF',
    h2: 'Borussia Dortmund',
    h3: 'Brand Introduction',

    h4: "In May 2019, the BA88 sports platform and the Bundesliga Dortmund club reached a strategic cooperation. This cooperation is a big step for BA88 sports to go international, and it is also a passionate cooperation between the young BA88 sports platform and the old Bundesliga giants. The goal of our cooperation is to enhance the brand effect of BA88 Sports and promote the Bundesliga on a global scale. When a cutting-edge force is committed to creating first-class products, cooperating with top partners, and shaping an outstanding brand image, it is ready to climb up and dominate the future. It is believed that the cooperation between the two parties will make the future of each other more open and bright.",
    h5: "Borussia Dortmund Football Club, a famous football club located in Dortmund, North Rhine-Westphalia (North Rhine-Westphalia), Germany, nicknamed ”Bumblebee“. The match between Borussia Dortmund, which rose after World War II, and Schalke 04, which is also located in the Ruhr Industrial Area (Ruhr Area) in North Rhine-Westphalia, was extremely popular due to its close geographical location and the same working-class culture. District Derby (Rourdby, Mining Derby)”; the team has performed very well in China in recent years, and the match with Bayern Munich has also been called the ”German National Derby“ by many fans. Dortmund has won 1 UEFA Champions League, 1 European Cup Winners' Cup, 1 Toyota Cup and 8 German top league titles.",
    d55: "In 2012, Borussia Dortmund won the Bundesliga title again, achieving a record 81 points, the highest ever in Bundesliga history. They also won the DFB-Pokal final by defeating Bayern Munich 5-2, becoming the domestic double champions for the first time in the club's history.",
    vt1: 'Event Venue',
    vt2: 'Valencia Club',
    vt3: 'Media Coverage',
    vt4: 'Exciting Moments',
    vt5: 'Star Players Shine',
    vt6: 'Industry\'s Best',
    vt7: 'Team Achievements',
    des1: 'Exclusive Partner for the Asia Region',
    des2: 'Official Partner',
    vp3: '<b>We are thrilled to announce that PP88 has reached a strategic cooperation agreement with Valencia Club.</b>As a globally renowned sports brand, PP88 has always been committed to providing the highest quality products and services, creating limitless possibilities for athletes and fans alike. We believe that the collaboration between PP88 and Valencia Club will bring new breakthroughs and opportunities to the football world. Together, we aim to create more exhilarating experiences for fans and contribute our efforts to the development of football. PP88 and Valencia Club, creating brilliance together!',
    vp4: 'Founded on March 18, 1919, Valencia Football Club currently competes in La Liga, the top-tier football league in Spain. Valencia is a team with a long and storied history. Since winning the Segunda División championship and earning promotion to La Liga in the 1930/31 season, the club has been a consistent presence in the league. The 1960s marked the team\'s golden era. In the 1961/62 fourth International Fair Cities Cup, the team scored 33 goals, setting a record for the most goals scored in the International Fair Cities Cup, including an 10-3 victory over Budapest MTK and a 7-3 sweep of Barcelona in the final. The following year, they secured their consecutive International Fair Cities Cup championship.',
    hp4: 'In May 2023, the BA88 Sports Platform and Borussia Dortmund Club entered into a strategic partnership. Our collaboration aims to enhance the brand impact of BA88 Sports and promote La Liga globally. As an emerging force committed to creating top-tier products and collaborating with elite partners, we believe that this cooperation will undoubtedly pave the way for a brighter future for both parties.',
    hp5: 'Borussia Dortmund is a famous football club located in Dortmund, North Rhine-Westphalia, Germany. They are nicknamed the "Yellow and Blacks" ("Die Schwarzgelben"). The matches between Borussia Dortmund, which rose after World War II, and FC Schalke 04, located in the Ruhr industrial region (Ruhrgebiet), are extremely fierce due to their close geographical proximity and the same working-class culture. It is known as the "Ruhr Derby" or "Miner\'s Derby." The team has had excellent performance domestically in recent years and the matches against Bayern Munich are also known as the "German National Derby" by many fans. Borussia Dortmund has won 1 UEFA Champions League title, 1 UEFA Cup Winners\' Cup, 1 Intercontinental Cup, and 8 German Bundesliga titles.',
    vt0: 'Brand Upgrade',
    hp6: 'The PP88 brand has evolved from BA88<br/>inheriting its essence while incorporating even more innovation to bring you a brand new experience.',
    vd2: 'Borussia Dortmund Club'

}
