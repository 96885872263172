<template>
    <div>
        <div class="ba-detail-swipe relative">
            <van-swipe :autoplay="8000" indicator-color="white">
                <van-swipe-item v-for="(item,index) in vanlencia_banner" :key="index">
                    <img class="ba-detail-swipe-item" :src="item.imgUrl"/>
                </van-swipe-item>
            </van-swipe>
            <img :src="banner2[$i18n.locale]"
                 style="position: absolute;top:0;left: 0;width: 100%;pointer-events: none;"/>
        </div>
        <div class="ba-detail-list">
            <div class="ba-detail-list-item" v-for="(item,index) in dortmund_list" :key="index"
                 :style="{backgroundImage:'url(' + dortmund_bgImg1 + ')'}">
                <div class="ba-detail-list-item-content"
                     :style="{padding:index === 4?'0':index === 2?'40px 0 25px':''}">
                    <div class="ba-detail-content-tag">
                        <img v-lazy="require('@/assets/images/mobile/valencia/text_bg.webp')"/>
                        <div>{{item.title}}</div>
                    </div>
                    <div v-if="index == 0"><!--现场 -->
                        <div class="item-video">
                            <video src="//pic.li3zq.com/video/vanlencia3.mp4" playsinline preload="metadata" controls
                                   :poster="require('@/assets/images/mobile/valencia/poster/poster2.jpg')"></video>
                        </div>
                        <div style="margin-top:10px;justify-content: space-between" class="ba-flex">
                            <img style="width:32%;" v-lazy="image" v-for="(image,index) in item.adImg" :key="index"/>
                        </div>
                        <div class="item-article" v-html="item.content">
                        </div>
                    </div>
                    <div v-if="index == 1"><!--俱乐部 -->
                        <div class="item-swipes">
                            <van-swipe :autoplay="autoplay" :show-indicators="true" indicator-color="#FFFFFF">
                                <van-swipe-item v-for="(banner,index) in item.banner" :key="index">
                                    <img class="ba-list-swipe-item-a" v-lazy="banner.imgUrl"/>
                                </van-swipe-item>
                            </van-swipe>
                        </div>
                        <div class="item-article" v-html="item.content">
                        </div>
                    </div>
                    <div v-if="index == 2"><!--媒体 -->
                        <div class="item-new-swipe">
                            <van-swipe :autoplay="autoplay" ref="swipe" :show-indicators="false"
                                       @change="(value)=>{return vanlencia_onChange(value,item)}">
                                <van-swipe-item v-for="(banner,index) in item.banner" :key="index">
                                    <img class="ba-list-swipe-item-n" v-lazy="banner.imgUrl[$i18n.locale]"/>
                                </van-swipe-item>
                            </van-swipe>
                            <div class="custom-indicator-n">
                                <div @click="vanlencia_onChange(index,item,0)"
                                     :style="{backgroundColor:index == item.current?'rgb(218, 114, 3)':'rgb(218, 114, 3,.5)'}"
                                     class="custom-indicator-n-line" v-for="(banner,index) in item.banner" :key="index">
                                </div>
                            </div>
                        </div>
                        <div class="item-new-bottom">
                            <img v-lazy="item.adImg"/>
                        </div>
                    </div>
                    <div v-if="index == 3"><!--精彩 -->
                        <div class="item-swipe">
                            <van-swipe :autoplay="autoplay" ref="swipe"
                                       @change="(value)=>{return vanlencia_onChangeScroll(value,item)}"
                                       :show-indicators="true" indicator-color="#FFFFFF">
                                <van-swipe-item v-for="(banner,index) in item.banner" :key="index">
                                    <img class="ba-list-swipe-item-a" v-lazy="banner.imgUrl"/>
                                </van-swipe-item>
                            </van-swipe>
                            <div class="custom-indicator-a van" ref="navContainer">
                                <div @click="vanlencia_onChangeScroll(index,item,1)"
                                     :class="index == item.current?'custom-indicator-a-img custom-indicator-a-img-active':'custom-indicator-a-img'"
                                     v-for="(banner,index) in item.banner" :key="index">
                                    <img v-lazy="banner.imgUrl"/>
                                </div>
                            </div>
                        </div>
                        <div class="item-news">
                            {{ item.banner[item.current].content }}
                        </div>
                    </div>
                    <div v-if="index == 4">
                        <div class="ba-time-list">
                            <div class="ba-column ba-time-line" style="padding: 30px 5% 40px;">
                                <div style="margin-top: 30px;" class="ba-flex" v-for="(banner,index) in item.banner"
                                     :key="index">
                                    <div class="ba-flex" style="width:13%">
                                        <img style="width:100%" v-lazy="banner.imgUrl"/>
                                    </div>
                                    <div style="max-width:78%;padding-left: 15px;" class="ba-column ba-time-line-item">
                                        <p class="ba-time" style="margin:0">{{ banner.time }}</p>
                                        <div class="ba-p" :class="$i18n.locale">
                                            <span>{{ banner.content }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="item-bg" style=" right: -19%;width: 77%;top: 13%;">
                                    <img v-lazy="item.adImg"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="index == 5"><!-- 球星闪耀 -->
                        <div class="item-swipe">
                            <van-swipe :autoplay="autoplay" ref="swipe"
                                       @change="(value)=>{return vanlencia_onChange(value,item)}"
                                       :show-indicators="false">
                                <van-swipe-item v-for="(banner,index) in item.banner" :key="index">
                                    <img class="ba-list-swipe-item" v-lazy="banner.imgUrl[$i18n.locale]"/>
                                </van-swipe-item>
                            </van-swipe>
                            <div class="custom-indicator-o">
                                <div @click="vanlencia_onChange(key,item,2)"
                                     :class="key == item.current?'custom-indicator-d-item custom-indicator-d-item-active':'custom-indicator-d-item'"
                                     v-for="(indicator,key) in item.banner" :key="key">
                                </div>
                            </div>
                        </div>
                        <div class="item-name">{{ item.banner[item.current].name }}</div>
                        <div class="item-article">
                            {{ item.banner[item.current].content }}
                        </div>
                    </div>
                    <div v-if="index == 6">
                        <div class="ba-about">
                            <div class="item-title">{{ $t('v1') }}</div>
                            <div class="item-desc">{{ $t('v2') }}</div>
                            <div class="ba-flex item-g">
                                <div class="ba-flex item-g-i" v-for="(item,index) in dortmund_glist" :key="index">
                                    <div style="width: fit-content">
                                        <div class="item-g-icon">
                                            <img v-lazy="item.icon"/>
                                        </div>
                                    </div>
                                    <div class="item-g-content ba-column" style="color:#da7203">
                                        <span class="item-g-content-title">{{ item.title }}</span>
                                        <span>{{ item.desco }}</span>
                                        <span>{{ item.desct }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="ba-flex ba-about-link">
                                <img class="ba-about-link-img" v-lazy="require('@/assets/images/linklogo.png')"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="PAGCOR" v-if="index == 6">
                    <img v-lazy="pagcor[$i18n.locale]">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {Swipe, SwipeItem} from "vant";

export default {
    name: 'valencia-team',
    props: {
        autoplay: Number,
    },
    components: {
        [Swipe.name]: Swipe,
        [SwipeItem.name]: SwipeItem,
    },
    data() {
        return {
            activePart1: {id: 1},
            activeSlide: '',
            showTopSwipe: false,
            vanlencia_banner: [
                {
                    path: "/detail/valencia.html",
                    imgUrl: require("@/assets/images/mobile/valencia/banner1.jpg"),
                },
                {
                    imgUrl: require("@/assets/images/mobile/valencia/banner-m1.jpg"),
                },
                {
                    imgUrl: require("@/assets/images/mobile/valencia/banner-m2.jpg"),
                },
                {
                    imgUrl: require("@/assets/images/mobile/valencia/banner-m3.jpg"),
                },
            ],
            banner2: {
                zh_CN: require("@/assets/images/mobile/banner_ZH.png"),
                zh_TW: require("@/assets/images/mobile/banner_TW.png"),
                en_US: require("@/assets/images/mobile/banner_EN.png"),
                en_MAS: require("@/assets/images/mobile/banner_MAS.png"),
                vi_VN: require("@/assets/images/mobile/banner_VN.png"),
            },
            dortmund_list: [
                {
                    height: '550px',
                    adImg: [require("@/assets/images/mobile/valencia/activity3.jpg"), require("@/assets/images/mobile/valencia/activity2.jpg"), require("@/assets/images/mobile/valencia/activity1.jpg")],
                    content: this.$t('v3'),
                    current: 0,
                    title: this.$t('vt1'),
                },
                {
                    height: '530px',
                    content: this.$t('v4'),
                    title: this.$t('vt2'),
                    banner: [
                        {
                            imgUrl: require("@/assets/images/mobile/valencia/img1.png"),
                        },
                        {
                            imgUrl: require("@/assets/images/mobile/valencia/banners1.jpg"),

                        },
                        {
                            imgUrl: require("@/assets/images/mobile/valencia/banners2.jpg"),
                        },
                        {
                            imgUrl: require("@/assets/images/mobile/valencia/banners3.jpg"),
                        },
                        {
                            imgUrl: require("@/assets/images/mobile/valencia/banners4.jpg"),

                        },
                    ],
                },
                {
                    adImg: require("@/assets/images/mobile/valencia/newlogo.jpg"),
                    title: this.$t('vt3'),
                    banner: [
                        {
                            imgUrl: {
                                zh_CN: require("@/assets/images/mobile/ZH_new1.jpg"),
                                zh_TW: require("@/assets/images/mobile/TW_new1.jpg"),
                                en_US: require("@/assets/images/mobile/EN_new1.jpg"),
                                en_MAS: require("@/assets/images/mobile/MS_new1.jpg"),
                                vi_VN: require("@/assets/images/mobile/VN_new1.jpg"),
                            },
                        },
                        {
                            imgUrl: {
                                zh_CN: require("@/assets/images/mobile/ZH_new2.jpg"),
                                zh_TW: require("@/assets/images/mobile/TW_new2.jpg"),
                                en_US: require("@/assets/images/mobile/EN_new2.jpg"),
                                en_MAS: require("@/assets/images/mobile/MS_new2.jpg"),
                                vi_VN: require("@/assets/images/mobile/VN_new2.jpg"),
                            },
                        },
                        {
                            imgUrl: {
                                zh_CN: require("@/assets/images/mobile/ZH_new3.jpg"),
                                zh_TW: require("@/assets/images/mobile/TW_new3.jpg"),
                                en_US: require("@/assets/images/mobile/EN_new3.jpg"),
                                en_MAS: require("@/assets/images/mobile/MS_new3.jpg"),
                                vi_VN: require("@/assets/images/mobile/VN_new3.jpg"),
                            },
                        },

                    ],
                    current: 0,
                },
                {
                    height: '500px',
                    title: this.$t('vt4'),
                    banner: [
                        {
                            imgUrl: require("@/assets/images/mobile/valencia/wonderful1.png"),
                            name: "",
                            content: this.$t('v5')
                        },
                        {
                            imgUrl: require("@/assets/images/mobile/valencia/wonderful2.png"),
                            name: "",
                            content: this.$t('v5')
                        },
                        {
                            imgUrl: require("@/assets/images/mobile/valencia/wonderful3.png"),
                            name: "",
                            content: this.$t('v5')
                        },
                        {
                            imgUrl: require("@/assets/images/mobile/valencia/wonderful4.png"),
                            name: "",
                            content: this.$t('v5')
                        },
                    ],
                    current: 0,
                },
                {
                    height: '520px',
                    title: this.$t('vt7'),
                    adImg: require("@/assets/images/mobile/valencia/logobg.png"),
                    banner: [
                        {
                            imgUrl: require("@/assets/images/mobile/valencia/f1.png"),
                            time: this.$t('v6'),
                            content: this.$t('v7')
                        },
                        {
                            imgUrl: require("@/assets/images/mobile/valencia/f3.png"),
                            time: this.$t('v8'),
                            content: this.$t('v9')
                        },
                        {
                            imgUrl: require("@/assets/images/mobile/valencia/f2.png"),
                            time: this.$t('v10'),
                            content: this.$t('v11')
                        },
                        {
                            imgUrl: require("@/assets/images/mobile/valencia/f4.png"),
                            time: this.$t('v12'),
                            content: this.$t('v13')
                        },
                        {
                            imgUrl: require("@/assets/images/mobile/valencia/f5.png"),
                            time: this.$t('v14'),
                            content: this.$t('v15')
                        }
                    ],
                    current: 0,
                },
                {
                    height: '500px',
                    title: this.$t('vt5'),
                    banner: [
                        {
                            imgUrl: {
                                zh_CN: require("@/assets/images/mobile/valencia/ZH_footboot1.png"),
                                zh_TW: require("@/assets/images/mobile/valencia/TW_footboot1.png"),
                                en_US: require("@/assets/images/mobile/valencia/EN_footboot1.png"),
                                en_MAS: require("@/assets/images/mobile/valencia/MS_footboot1.png"),
                                vi_VN: require("@/assets/images/mobile/valencia/MS_footboot1.png"),
                            },
                            name: this.$t('v16'),
                            content: this.$t('v17')
                        },
                        {
                            imgUrl: {
                                zh_CN: require("@/assets/images/mobile/valencia/ZH_footboot2.png"),
                                zh_TW: require("@/assets/images/mobile/valencia/TW_footboot2.png"),
                                en_US: require("@/assets/images/mobile/valencia/EN_footboot2.png"),
                                en_MAS: require("@/assets/images/mobile/valencia/MS_footboot2.png"),
                                vi_VN: require("@/assets/images/mobile/valencia/MS_footboot2.png"),
                            },
                            name: this.$t('v18'),
                            content: this.$t('v19')
                        },
                        {
                            imgUrl: {
                                zh_CN: require("@/assets/images/mobile/valencia/ZH_footboot3.png"),
                                zh_TW: require("@/assets/images/mobile/valencia/TW_footboot3.png"),
                                en_US: require("@/assets/images/mobile/valencia/EN_footboot3.png"),
                                en_MAS: require("@/assets/images/mobile/valencia/MS_footboot3.png"),
                                vi_VN: require("@/assets/images/mobile/valencia/MS_footboot3.png"),
                            },
                            name: this.$t('v20'),
                            content: this.$t('v21'),
                        },
                        {
                            imgUrl: {
                                zh_CN: require("@/assets/images/mobile/valencia/ZH_footboot4.png"),
                                zh_TW: require("@/assets/images/mobile/valencia/TW_footboot4.png"),
                                en_US: require("@/assets/images/mobile/valencia/EN_footboot4.png"),
                                en_MAS: require("@/assets/images/mobile/valencia/MS_footboot4.png"),
                                vi_VN: require("@/assets/images/mobile/valencia/MS_footboot4.png"),
                            },
                            name: this.$t('v22'),
                            content: this.$t('v23'),
                        },
                        {
                            imgUrl: {
                                zh_CN: require("@/assets/images/mobile/valencia/ZH_footboot5.png"),
                                zh_TW: require("@/assets/images/mobile/valencia/TW_footboot5.png"),
                                en_US: require("@/assets/images/mobile/valencia/EN_footboot5.png"),
                                en_MAS: require("@/assets/images/mobile/valencia/MS_footboot5.png"),
                                vi_VN: require("@/assets/images/mobile/valencia/MS_footboot5.png"),
                            },
                            name: this.$t('v24'),
                            content: this.$t('v25'),
                        }
                    ],
                    current: 0,
                },
                {
                    title: this.$t('vt6'),
                    height: '540px',
                },
            ],
            dortmund_glist: [
                {
                    title: this.$t('v26'),
                    icon: require("@/assets/images/mobile/valencia/icon-1.png"),
                    desco: this.$t('v27'),
                    desct: this.$t('v28'),
                },
                {
                    title: this.$t('v29'),
                    icon: require("@/assets/images/mobile/valencia/icon-2.png"),
                    desco: this.$t('v30'),
                    desct: this.$t('v31'),
                },
                {
                    title: this.$t('v32'),
                    icon: require("@/assets/images/mobile/valencia/icon-3.png"),
                    desco: this.$t('v33'),
                    desct: this.$t('v34'),
                },
                {
                    title: this.$t('v35'),
                    icon: require("@/assets/images/mobile/valencia/icon-4.png"),
                    desco: this.$t('v36'),
                    desct: this.$t('v37'),
                }
            ],
            dortmund_bgImg1: require("@/assets/images/mobile/valencia/bg.png"),
            pagcor: {
                zh_CN: require('@/assets/images/mobile/ZH_PAGCOR.png'),
                zh_TW: require('@/assets/images/mobile/TW_PAGCOR.png'),
                en_US: require('@/assets/images/mobile/EN_PAGCOR.png'),
                en_MAS: require('@/assets/images/mobile/MS_PAGCOR.png'),
                vi_VN: require('@/assets/images/mobile/VN_PAGCOR.png'),
            }
        }
    },
    mounted() {
        setTimeout(() => {
            this.showTopSwipe = true;
        }, 100)
    },
    methods: {
        vanlencia_onChange(index, item, key) {
            item.current = index;
            if (key >= 0) {
                this.$refs.swipe[key].swipeTo(index)
            }
        },
        vanlencia_onChangeScroll(index, item, key) {
            item.current = index;
            if (key >= 0) {
                console.log(this.$refs.swipe)
                this.$refs.swipe[key].swipeTo(index);
            }
            let container = this.$refs.navContainer[0]
            let item2 = document.querySelectorAll('.van .custom-indicator-a-img')[index];
            if (item2) {
                var itemOffsetLeft = item2.offsetLeft;
                var centerLeft = (container.offsetWidth - item2.offsetWidth) / 2;
                let res = itemOffsetLeft - centerLeft;
                container.scrollLeft = res;
            }
        },
    }
}
</script>
<style scoped>
.ba-detail{
    display: flex;
    flex-direction: column;
}

.ba-detail-swipe-item{
    width: 100%;
    height: 100%;
    display: flex;
}
.ba-detail-swipe-item img{
    width: 100%;
    height: 100%;
}
.ba-detail-content-tag{
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    top:-8px;
    left: 0;
}
.ba-detail-content-tag img{
    width: 62%;
}
.ba-detail-content-tag div{
    position: absolute;
    top: 50%;
    left:50%;
    color:#FFFFFF;
    transform: translate(-50%, -65%);
}
.ba-detail-list{
    margin-top:-10px;
}
.ba-detail-list-item{
    padding:53px 4%;
    background-size: 100% 100%;
}
.ba-detail-list-item-content{
    padding:40px 5% 30px;
    border-radius: 15px;
    position: relative;
    background-color: #ffffff;
}

.item-video-container{
    width: 100%;
    overflow-x: hidden;
    white-space: nowrap;
    position: relative;
}

.item-video{
    display: inline-block;
    width: 100%;
    transition: transform 0.5s ease;
}
.item-video video{
    width: 100%;
    height: 100%;
    border-radius: 10px;
    z-index: 0;
}
.item-article{
    margin-top:10px;
    font-size: 12px;
    font-family: 'PingfangBold';
    line-height: 20px;
    color: rgba(102, 102, 102, 1);
    overflow: hidden;
    overflow-y: scroll;
}
.item-title{
    margin-top:10px;
    text-align: center;
    font-size: 26px;
    font-weight: bold;
}
.item-desc{
    text-align: center;
    color: #666;
    font-size: 14px;
    margin-top: 10px;
}
.ba-flex{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.item-g{
    padding:0 8%;
    margin-top:30px;
    justify-content: space-between;
    align-items: flex-start;
}
.ba-column{
    display: flex;
    flex-direction: column;
}
.item-g-icon{
    width: 30px;
    height: 30px;
}
.item-g-icon img{
    width: 100%;
    height: 100%;
}
.item-g-i{
    align-items: flex-start;
    flex-wrap: nowrap;
    width: 50%;
    margin-bottom: 30px;
}
.item-g-content{
    margin-left: 10px;
    color:#981008;
    font-size: 10px;
}
.item-g-content-title{
    font-size: 12px;
    font-family: 'PingfangBold';
    margin-bottom: 2px;
}
.ba-about{
    width: 100%;
}
.ba-about-link{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ba-about-link-img{
    width: 70%;
}
.ba-list-swipe-item{
    width: 100%;
}
.custom-indicator-o{
    margin:5px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.item-name{
    font-size: 14px;
    font-family: 'PingfangBold';
    color: rgba(51, 51, 51, 1);
    margin-top: 10px;
}
.ba-list-swipe-item-a{
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
    //height: 180px;
}
.custom-indicator-a{
    margin-top: 10px;
    white-space: nowrap;
    overflow: hidden;
    overflow-x: auto;
  scroll-behavior:smooth
}
.custom-indicator-a::-webkit-scrollbar{
    display: none;
}
.custom-indicator-a-img{
    width: 30.6%;
    display: inline-block;
    border:2px solid #ffffff;
    height: 60px;
    border-radius: 4px;
    overflow: hidden;
    margin-right: 1.7%;
}
.custom-indicator-a-img img{
    width: 100%;
    height: 100%;
}
.custom-indicator-a-img-active{
    border:4px solid #ffd801
}
.PAGCOR{
    width: 100%;
    margin-top: 15px !important;
}
.PAGCOR img{
    width: 100%;
}
.custom-indicator-o-item{
    height: 5px;
    width: 6%;
    background-color: #e0a09e;
    border-radius: 10px;
    margin: 0 3px;
}
.custom-indicator-o-item-active{
    background-color: #c2433d !important;
}
.custom-indicator-l-item{
    height: 5px;
    width: 6%;
    background-color: #accfeb;
    border-radius: 10px;
    margin: 0 3px;
}
.custom-indicator-d-item-active{
    background-color: #ce880e !important;
}
.custom-indicator-d-item{
    height: 5px;
    width: 6%;
    background-color: #e5c386;
    border-radius: 10px;
    margin: 0 3px;
}
.custom-indicator-l-item-active{
    background-color: #599fdb !important;
}
.custom-indicator-f-item{
    height: 5px;
    width: 6%;
    background-color: #e5c386;
    border-radius: 10px;
    margin: 0 3px;
}
.custom-indicator-f-item-active{
    background-color: #ce880f !important;
}
.item-news{
    margin-top: 5px;
    font-size: 12px;
    font-family: 'PingfangBold';
    line-height: 20px;
    color: rgba(102, 102, 102, 1);
    overflow: hidden;
    overflow-y: scroll;
}
:deep .van-swipe__indicator{
    width: 15px !important;
    height: 3px !important;
    border-radius: 3px !important;
}
:deep .van-swipe__indicators{
    bottom: 15px !important;
}
.item-img{
    display: flex;
    justify-content: center;
    width: 100%;
}
.item-img img{
    width: 100%;
}
/*@font-face {*/
/*    font-family: "PingfangBold";*/
/*    src: url("@/assets/font/PingFangBold.woff2");*/
/*}*/
.ba-time-list{
    position: relative;
    overflow: hidden;
}
.item-bg{
    position: absolute;
    right: -15px;
    width: 58%;
    top: 20%;
}
.item-bg img{
    width: 100%;
    height: 100%;
}
.ba-time{
    font-size: 3.5vw;
    font-family: 'PingfangBold';
    color: rgba(102, 102, 102, 1);
}
.ba-p{
    font-size: 4vw;
    font-family: 'PingfangBold';
    margin-left: 2%;
    color:#da7203;margin-left:0
}

.ba-p.zh_CN,
.ba-p.zh_TW{
    font-weight: bold;
}
.ba-p.en_US,
.ba-p.en_MAS{
    font-weight: bold;
    font-size: 5vw;
}

.ba-time-line-item{

}
.ba-time-line{
    padding-bottom: 10px;
}
.card-carousel{
    margin-top: 10px;
}
.card-carousel .el-carousel__button{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #599fdb;
}
.card-carousel .el-carousel__container .is-active{
    width: 80%;
    transform: translateX(13%) scale(1) !important;
}
.card-carousel .el-carousel__arrow i{
    opacity: 0;
}
.card-carousel .el-carousel__item--card.is-in-stage{
    display: flex;
    align-items: center;
}
.card-carousel .el-carousel__indicators--outside{
    margin-top: 10px;
}
.item-card-article{
    font-size: 12px;
    font-family: 'PingfangBold';
    color: rgba(51, 51, 51, 1);
    text-align: center;
    padding-bottom: 20px;
}
.ba-list-swipe-item-n{
    width: 100%;
}
.item-new-bottom{
    margin-top: 8px;
    display: flex;
    justify-content: center;
}
.item-new-bottom img{
    width: 80%;
}
.custom-indicator-n{
    display: flex;
    margin:10px 0 20px;
    align-items: center;
    justify-content: center;
}
.custom-indicator-n-line{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin:0 3px
}
:deep [lazy="loading"] {
height:0;
}

</style>
