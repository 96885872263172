export default {
    v1: '专业的原生娱乐APP',
    v2: '高效率 最安全 最极致的用户体验',
    v3: '<b>我们非常高兴地宣布，PP88与瓦伦西亚俱乐部达成了战略合作协议。</b><br/>作为两个领域中的领军者，我们相信这次合作将为球迷们带来更加难忘的体验。作为全球知名的体育品牌，PP88一直以来致力于提供最优质的产品和服务，为运动员和球迷们创造无限可能。瓦伦西亚俱乐部则是西班牙足球的传奇之一，拥有众多忠诚的球迷和杰出的球队成绩。我们相信，PP88与瓦伦西亚俱乐部的合作将为足球界带来新的突破和机遇。我们期待着与瓦伦西亚俱乐部的合作，共同为球迷们创造更加激动人心的体验，为足球运动的发展贡献我们的力量。PP88与瓦伦西亚俱乐部，共创辉煌！',
    v4: '瓦伦西亚足球俱乐部，成立于1919年3月18日，位于西班牙的第三大城市瓦伦西亚，现参加西班牙足球甲级联赛，瓦伦西亚是一支有着悠久历史的老牌球队。瓦伦西亚是欧洲闻名的劲旅之一，绝大部分时间都是在西甲中度过的，自从1930/31赛季夺得西乙冠军升上西甲之后，长时间参加西甲联赛，直到1985/86赛季意外降级，其后即以西乙冠军身份重返西甲，自此球队一直参加西甲联赛。60年代为球队最光辉的时刻，1961/62第四届国际城市博览会杯，球队攻入33个进球，创造了国际城市博览会杯比赛的进球最高纪录，当中包括8强10:3狂胜布达佩斯MTK，决赛7:3横扫巴塞罗那。第二年，蝉联了国际城市博览会杯的冠军。',
    v5: '瓦伦西亚曾赢得6次西甲冠军、8次西班牙国王杯冠军、3次欧洲足协杯冠军、1次欧洲杯赛冠军杯冠军和2次欧洲超霸杯。瓦伦西亚是西班牙传统五强之一，瓦伦西亚也是G-14成员之一。',
    v6: '西班牙足球甲级联赛',
    v7: '6次冠军',
    v8: '西班牙国王杯',
    v9: '8次冠军',
    v10: '西班牙超级杯',
    v11: '1次冠军',
    v12: '欧洲联盟杯',
    v13: '1次冠军',
    v14: '欧洲超级杯冠军',
    v15: '2次冠军',
    v16: '埃丁森·卡瓦尼 Edinson Roberto Cavani Gómez',
    v17: '出生于1987年2月14日，是一名乌拉圭职业足球运动员，司职前锋，现效力于西甲球队瓦伦西亚。',
    v18: '沙姆·卡斯蒂列霍 Samu Castillejo',
    v19: '出生于1995年1月18日，是一位西班牙足球运动员。在场上的位置是右边锋。现效力于西甲球队瓦伦西亚。他曾代表西班牙国家青年足球队参赛。',
    v20: '穆塔尔·迪亚卡比 Mouctar Diakhaby',
    v21: '出生于1996年12月19日，是一名职业足球运动员，司职中后卫，现效力于西甲俱乐部瓦伦西亚。出生于法国。',
    v22: '安德烈·阿尔梅达 Domingos André Ribeiro Almeida',
    v23: '出生于2000年5月30日，葡萄牙职业足球运动员，司职中场，效力于西甲球队瓦伦西亚。',
    v24: '埃尔文·科梅尔特 Eray Ervin Cömert',
    v25: '出生于1998年2月4日，是一名瑞士职业足球运动员，担任西甲俱乐部瓦伦西亚的后卫。',
    v26: '更安全',
    v27: '独家网络技术',
    v28: '超强防御劫持',
    v29: '更丰富',
    v30: '丰富的游戏产品',
    v31: '您想要的我们都有',
    v32: '更稳定',
    v33: '强大的技术团队',
    v34: '提供最稳定的产品',
    v35: '更私密',
    v36: '三重数据加密',
    v37: '保护您的数据安全',

    d3: '2019年5月，BA88体育平台和德甲多特蒙德俱乐部达成战略合作，本次合作是BA88体育迈向国际化的一大步，也是年轻的BA88体育平台与老牌德甲豪门的一次激情合作。我们的合作目标是提高BA88体育品牌效应并在全球范围内推广德甲联赛。当一支新锐力量致力于打造一流产品、与顶尖合作伙伴、塑造杰出品牌形象，它已准备好向上攀升，主宰未来。相信双方此次的合作定会让彼此的前途更加坦荡无阻、一片光明。',
    d4: '多特蒙德足球俱乐部，位于德国北莱茵-威斯特法伦州多特蒙德市的著名足球俱乐部，绰号「大黄蜂」。二战后崛起的多特蒙德和同处北威州鲁尔工业区（鲁尔区）的沙尔克04之间的比赛因紧邻的地理位置、相同的工人阶层文化而火爆异常，被称为「鲁尔区德比（鲁尔德比、矿区德比）」；球队在国内近些年成绩非常好，和拜仁慕尼黑之间的比赛也被不少球迷称作「德国国家德比」。多特蒙德曾获得1次欧洲冠军联赛冠军，1次欧洲优胜者杯冠军，1次丰田杯冠军和8次德国顶级联赛冠军。',
    d5: '2012年多特蒙德再次获得德甲冠军，并以81分成为德甲史上最高分的冠军。同时也在德国足协杯决赛以5:2击败拜仁慕尼黑夺冠，是俱乐部历史上首次成为国内双冠王。',
    d6: '2011年',
    d7: '德国足球甲级联赛，获得冠军',
    d8: '2012年',
    d9: '德国足球甲级联赛，成功卫冕冠军',
    d10: '2012/13赛季欧洲冠军联赛',
    d11: '德国足球甲级联赛，对阵拜仁慕尼黑，惜败，获得亚军',
    d12: '2014年',
    d13: '德国超级杯，击败拜仁慕尼黑获得冠军',
    d14: '马尔科·罗伊斯（Marco Reus）',
    d15: '1989年5月31日出生于德国多特蒙德，德国足球运动员，场上司职中场/前锋，效力于德甲的多特蒙德足球俱乐部，亦担任球队队长',
    d16: '乔瓦尼·雷纳（Giovanni Reyna）',
    d17: '2002年11月13日出生于英格兰达勒姆，美国足球运动员，场上司职中场，现效力于德甲多特蒙德足球俱乐部',
    d18: '尼科·舒尔茨（Nico Schulz）',
    d19: '1993年4月1日出生于柏林，是一名德国职业足球运动员，场上位置为左后卫，现效力于德甲多特蒙德足球俱乐部',
    d20: '费利克斯·帕斯拉克（Felix Passlack）',
    d21: '1998年5月29日出生于德国波特洛普，德国职业足球运动员，场上司职右后卫，现效力于德甲的多特蒙德足球俱乐部',
    d22: '托马斯·默尼耶（Thomas Meunier）',
    d23: '1991年9月12日出生于比利时圣奥德，比利时足球运动员，司职右后卫/翼卫，效力于德甲多特蒙德足球俱乐部',
    p3: '品牌赞助',
    p4: '本公司PP88体育所有产品与客户服务、软件系统、网络架构等相关业务事宜，皆由菲律宾政府认证机构所授权和监管，我们是一家深受广大在线玩家信赖的在线娱乐公司，我们提供了完整的在线娱乐游戏服务给我们的广大会员，并且我们也拥有国际级的娱乐信息专家及市场策略专家，可以让会员在游戏使用上更加方便，让娱乐项目能够专业化，我们坚持以公正、安全的信念为客户在网上打造别树一幟的娱乐品牌。我们备有完整的监督机制及严肃的处理规定，以确保所有会员的游戏娱乐都能在公平、公正、公开的状态下进行，我们使用了极好的安全技术特性，能在玩家和游戏之间的信息传递上毫无漏洞，复杂的算法来进行数据加密，为了就是希望能保护玩家的所有账户数据和游戏结构正确、公平与公正。为了让我们尊贵的客户可以最方便、最快速地得到所有关于线上游戏与会员权益等相关问题，我们特别设立24小时客户服务中心，以最亲切、最礼貌的服务宗旨来为玩家解答所有问题，不管是任何疑问，推广意见，赞扬或投诉，我们的客服中心都欢迎您随时在线光临。',
    p5: '媒体报道 品牌合作',
    p6: '各大媒体平台争相报道PP88体育与瓦伦西亚俱乐部的签约盛况，这些报道的出版进一步提升了PP88体育平台在世界上的品牌影响力。',
    p7: '合法经营 政府监管',
    p8: '关于PAGCOR',
    p9: '菲律宾博彩执照—PAGCOR（Philippine Amusement and Gaming Corporation）PAGCOR是菲律宾有合法经营权的权威组织。',
    p10: 'PAGCOR证书',

    h1: '瓦伦西亚俱乐部',
    h2: '多特蒙德俱乐部',
    h3: '品牌介绍',

    h4:'2023年5月，BA88体育平台和多特蒙德俱乐部达成战略合作，本次合作是BA88体育迈向国际化的一大步，也是年轻的BA88体育平台与西甲豪门的一次激情合作。我们的合作目标是提高BA88体育品牌效应并在全球范围内推广西甲联赛。当一支新锐力量致力于打造一流产品、与顶尖合作伙伴、塑造杰出品牌形象，它已准备好向上攀升，主宰未来。相信双方此次的合作定会让彼此的前途更加坦荡无阻、一片光明。',
    h5:"多特蒙德足球俱乐部，成立于1919年3月18日，位于西班牙的第三大城市多特蒙德，现参加西班牙足球甲级联赛，多特蒙德是一支有着悠久历史的老牌球队。多特蒙德是欧洲闻名的劲旅之一，绝大部分时间都是在西甲中度过的，自从1930/31赛季夺得西乙冠军升上西甲之后，长时间参加西甲联赛，直到1985/86赛季意外降级，其后即以西乙冠军身份重返西甲，自此球队一直参加西甲联赛。60年代为球队最光辉的时刻，1961/62第四届国际城市博览会杯，球队攻入33个进球，创造了国际城市博览会杯比赛的进球最高纪录，当中包括8强10:3狂胜布达佩斯MTK，决赛7:3横扫巴塞罗那。第二年，蝉联了国际城市博览会杯的冠军。",
    d55:'2012年多特蒙德再次获得德甲冠军，并以81分成为德甲史上最高分的冠军。同时也在德国足协杯决赛以5:2击败拜仁慕尼黑夺冠，是俱乐部历史上首次成为国内双冠王。',

    vt1:'活动现场',
    vt2:'瓦伦西亚俱乐部',

    vt3:'媒体报道',
    vt4:'精彩瞬间',
    vt5:'球星闪耀',
    vt6:'业界最佳',
    vt7:'球队成就',
    des1:'亚洲区独家合作伙伴',
    des2:'官方合作伙伴',

    vp3: '<b>我们非常高兴地宣布，PP88与瓦伦西亚俱乐部达成了战略合作协议。</b><br/>作为全球知名的体育品牌，PP88一直以来致力于提供最优质的产品和服务，为运动员和球迷们创造无限可能。我们相信，PP88与瓦伦西亚俱乐部的合作将为足球界带来新的突破和机遇。共同为球迷们创造更加激动人心的体验，为足球运动的发展贡献我们的力量。PP88与瓦伦西亚俱乐部，共创辉煌！',
    vp4: '瓦伦西亚足球俱乐部，成立于1919年3月18日，现参加西班牙足球甲级联赛，瓦伦西亚是一支有着悠久历史的老牌球队。自从1930/31赛季夺得西乙冠军升上西甲之后，长时间参加西甲联赛，60年代为球队最光辉的时刻，1961/62第四届国际城市博览会杯，球队攻入33个进球，创造了国际城市博览会杯比赛的进球最高纪录，当中包括8强10:3狂胜布达佩斯MTK，决赛7:3横扫巴塞罗那。第二年，蝉联了国际城市博览会杯的冠军。',

    hp4:'2023年5月，BA88体育平台和多特蒙德俱乐部达成战略合作。我们的合作目标是提高BA88体育品牌效应并在全球范围内推广西甲联赛。当一支新锐力量致力于打造一流产品、与顶尖合作伙伴。相信双方此次的合作定会让彼此的前途更加坦荡无阻、一片光明。',
    hp5:"多特蒙德足球俱乐部，位于德国北莱茵-威斯特法伦州多特蒙德市的著名足球俱乐部，绰号「大黄蜂」。二战后崛起的多特蒙德和同处北威州鲁尔工业区（鲁尔区）的沙尔克04之间的比赛因紧邻的地理位置、相同的工人阶层文化而火爆异常，被称为「鲁尔区德比（鲁尔德比、矿区德比）」；球队在国内近些年成绩非常好，和拜仁慕尼黑之间的比赛也被不少球迷称作「德国国家德比」。多特蒙德曾获得1次欧洲冠军联赛冠军，1次欧洲优胜者杯冠军，1次丰田杯冠军和8次德国顶级联赛冠军。",
    vt0:'品牌升级',
    vd2:'多特蒙德俱乐部',
    hp6:'PP88品牌从BA88升级而来<br/>延承其精髓，融入更多创新，为您带来全新体验。',
}
