<template>
    <VanlenciaGame v-if="teamType === '1'" @change="changeTab"></VanlenciaGame>
    <DortmudGame v-else @change="changeTab"></DortmudGame>
    <!--        <LeicesterGame v-else @change="changeTab"/>-->
</template>
<script setup>
import {ref} from "vue";
import DortmudGame from "@/DortmudGame.vue";
import VanlenciaGame from "@/VanlenciaGame.vue";

const teamType = ref('1')

function changeTab(value) {
    teamType.value = value;
}
</script>
<style>
@import url("//unpkg.com/element-ui@2.9.1/lib/theme-chalk/index.css");

.fp-watermark {
    display: none;
}
</style>