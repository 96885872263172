export default {
    v1: '專業的原生娛樂APP',
    v2: '高效率 最安全 最極致的使用者體驗',
    v3: '<b>我們非常高興地宣布，PP88與瓦倫西亞俱樂部達成了戰略合作協議。</b><br/>作為兩個領域中的領導者，我們相信這次合作將為球迷們帶來更加難忘的體驗。作為全球知名的體育品牌，PP88一直以來致力於提供最優質的產品和服務，為運動員和球迷們創造無限可能。瓦倫西亞俱樂部則是西班牙足球的傳奇之一，擁有眾多忠誠的球迷和傑出的球隊成績。我們相信，PP88與瓦倫西亞俱樂部的合作將為足球界帶來新的突破和機遇。我們期待著與瓦倫西亞俱樂部的合作，共同為球迷們創造更加激動人心的體驗，為足球運動的發展貢獻我們的力量。PP88與瓦倫西亞俱樂部，共創輝煌！',
    v4: '瓦倫西亞足球俱樂部，成立於1919年3月18日，位於西班牙的第三大城市瓦倫西亞，現參加西班牙足球甲級聯賽，瓦倫西亞是一支有著悠久歷史的老牌球隊。瓦倫西亞是歐洲聞名的劍旅之一，絕大部分時間都是在西甲中度過的，自從1930/31賽季奪得西乙冠軍升上西甲之後，長時間參加西甲聯賽，直到1985/86賽季意外降級，其後即以西乙冠軍身份重返西甲，自此球隊一直參加西甲聯賽。60年代為球隊最光輝的時刻，1961/62第四屆國際城市博覽會杯，球隊攻入33個進球，創造了國際城市博覽會杯比賽的進球最高紀錄，當中包括8強10:3狂勝布達佩斯MTK，決賽7:3橫掃巴塞羅那。第二年，蟬聯了國際城市博覽會杯的冠軍。',
    v5: '瓦倫西亞曾贏得6次西甲冠軍、8次西班牙國王盃冠軍、3次歐洲足協盃冠軍、1次歐洲盃賽冠軍盃冠軍和2次歐洲超霸杯。瓦倫西亞是西班牙傳統五強之一，瓦倫西亞也是G-14成員之一。',
    v6: '西班牙足球甲級聯賽',
    v7: '6次冠軍',
    v8: '西班牙國王盃',
    v9: '8次冠軍',
    v10: '西班牙超級盃',
    v11: '1次冠軍',
    v12: '歐洲聯盟杯',
    v13: '1次冠軍',
    v14: '歐洲超級盃冠軍',
    v15: '2次冠軍',
    v16: '埃丁森·卡瓦尼 Edinson Roberto Cavani Gómez',
    v17: '他出生於1987年2月14日，是一名烏拉圭職業足球運動員，司職前鋒，現效力於西甲球隊瓦倫西亞。',
    v18: '沙姆·卡斯蒂列霍 Samu Castillejo',
    v19: '出生於1995年1月18日，是一位西班牙足球運動員。在場上的位置是右邊鋒。現效力於西甲球隊瓦倫西亞。他曾代表西班牙國家青年足球隊參賽。',
    v20: '穆塔爾·迪亞卡比 Mouctar Diakhaby',
    v21: '出生於1996年12月19日，是一名職業足球運動員，司職中後衛，現效力於西甲俱樂部瓦倫西亞。出生於法國。',
    v22: '安德烈·阿爾梅達 Domingos André Ribeiro Almeida',
    v23: '出生於2000年5月30日，葡萄牙職業足球運動員，司職中場，效力於西甲球隊瓦倫西亞。',
    v24: '埃爾文·科梅爾特 Eray Ervin Cömert',
    v25: '出生於1998年2月4日，是一名瑞士職業足球運動員，擔任西甲俱樂部瓦倫西亞的後衛。',
    v26: '更安全',
    v27: '獨家網絡技術',
    v28: '超強防御劫持',
    v29: '更豐富',
    v30: '豐富的遊戲產品',
    v31: '您想要的我們都有',
    v32: '更穩定',
    v33: '強大的技術團隊',
    v34: '提供最穩定的產品',
    v35: '更私密',
    v36: '三重數據加密',
    v37: '保護您的數據安全',

    d3: '2019年5月，BA88體育平台和德甲多特蒙德俱樂部達成戰略合作，本次合作是BA88體育邁向國際化的一大步，也是年輕的BA88體育平台與老牌德甲豪門的一次激情合作。我們的合作目標是提高BA88體育品牌效應並在全球範圍內推廣德甲聯賽。當一支新銳力量致力於打造一流產品、與頂尖合作夥伴、塑造傑出品牌形象，它已準備好向上攀升，主宰未來。相信雙方此次的合作定會讓彼此的前途更加坦蕩無阻、一片光明。',
    d4: '多特蒙德足球俱樂部，位於德國北萊茵-威斯特法倫州多特蒙德市的著名足球俱樂部，綽號「大黃蜂」。二戰後崛起的多特蒙德和同處北威州魯爾工業區（魯爾區）的沙爾克04之間的比賽因緊鄰的地理位置、相同的工人階級文化而火爆異常，被稱為「魯爾區德比（魯爾德比、礦區德比）」；球隊在國內近些年成績非常好，和拜仁慕尼黑之間的比賽也被不少球迷稱作「德國國家德比」。多特蒙德曾獲得1次歐洲冠軍聯賽冠軍，1次歐洲優勝者杯冠軍，1次豐田杯冠軍和8次德國頂級聯賽冠軍。',
    d5: '2012年多特蒙德再次獲得德甲冠軍，並以81分成為德甲史上最高分的冠軍。同時也在德國足協盃決賽以5:2擊敗拜仁慕尼黑奪冠，是俱樂部歷史上首次成為國內雙冠王。',
    d6: '2011年',
    d7: '德國足球甲級聯賽，獲得冠軍',
    d8: '2012年',
    d9: '德國足球甲級聯賽，成功衛冕冠軍',
    d10: '2012/13賽季歐洲冠軍聯賽',
    d11: '德國足球甲級聯賽，對陣拜仁慕尼黑，惜敗，獲得亞軍',
    d12: '2014年',
    d13: '德國超級盃，擊敗拜仁慕尼黑獲得冠軍',
    d14: '馬爾科·羅伊斯（Marco Reus）',
    d15: '1989年5月31日出生於德國多特蒙德，德國足球運動員，場上司職中場/前鋒，效力於德甲的多特蒙德足球俱樂部，亦擔任球隊隊長',
    d16: '喬瓦尼·雷納（Giovanni Reyna）',
    d17: '2002年11月13日出生於英格蘭達勒姆，美國足球運動員，場上司職中場，現效力於德甲多特蒙德足球俱樂部',
    d18: '尼科·舒爾茨（Nico Schulz）',
    d19: '1993年4月1日出生於柏林，是一名德國職業足球運動員，場上位置為左後衛，現效力於德甲多特蒙德足球俱樂部',
    d20: '費利克斯·帕斯拉克（Felix Passlack）',
    d21: '1998年5月29日出生於德國波特洛普，德國職業足球運動員，場上司職右後衛，現效力於德甲的多特蒙德足球俱樂部',
    d22: '托馬斯·梅尼耶（Thomas Meunier）',
    d23: '1991年9月12日出生於比利時聖奧德，比利時足球運動員，司職右後衛/翼衛，效力於德甲多特蒙德足球俱樂部',
    p3: '品牌贊助',
    p4: '本公司PP88體育所有產品與客戶服務、軟體系統、網絡架構等相關業務事宜，皆由菲律賓政府認證機構所授權和監管，我們是一家深受廣大在線玩家信賴的在線娛樂公司，我們提供了完整的在線娛樂遊戲服務給我們的廣大會員，並且我們也擁有國際級的娛樂信息專家及市場策略專家，可以讓會員在遊戲使用上更加方便，讓娛樂項目能夠專業化，我們堅持以公正、安全的信念為客戶在網上打造別樹一幟的娛樂品牌。我們備有完整的監督機制及嚴肅的處理規定，以確保所有會員的遊戲娛樂都能在公平、公正、公開的狀態下進行，我們使用了極好的安全技術特性，能在玩家和遊戲之間的信息傳遞上毫無漏洞，複雜的演算法來進行數據加密，為了就是希望能保護玩家的所有帳戶數據和遊戲結構正確、公平與公正。為了讓我們尊貴的客戶可以最方便、最快速地得到所有關於線上遊戲與會員權益等相關問題，我們特別設立24小時客戶服務中心，以最親切、最禮貌的服務宗旨來為玩家解答所有問題，不管是任何疑問，推廣意見，讚揚或投訴，我們的客服中心都歡迎您隨時在線光臨。',
    p5: '媒體報導 品牌合作',
    p6: '各大媒體平台爭相報導PP88體育與瓦倫西亞俱樂部的簽約盛況，這些報導的出版進一步提升了PP88體育平台在世界上的品牌影響力。',
    p7: '合法經營 政府監管',
    p8: '關於PAGCOR',
    p9: '菲律賓博彩執照—PAGCOR（Philippine Amusement and Gaming Corporation）PAGCOR是菲律賓有合法經營權的權威組織。',
    p10: 'PAGCOR證書',

    h1: '瓦倫西亞俱樂部',
    h2: '多特蒙德俱樂部',
    h3: '品牌介紹',
    h4: "於2023年5月，BA88體育平台與多特蒙德俱樂部達成戰略合作。這次合作是BA88體育邁向國際化的一大步，也是年輕的BA88體育平台與西甲豪門的一次激情合作。我們的合作目標是提高BA88體育品牌效應並在全球範圍內推廣西甲聯賽。當一支新銳力量致力於打造一流產品、與頂尖合作夥伴、塑造傑出品牌形象，它已準備好向上攀升，主宰未來。相信雙方此次的合作定會讓彼此的前途更加坦蕩無阻、一片光明。",
    h5: "多特蒙德足球俱樂部，成立於1919年3月18日，位於西班牙的第三大城市多特蒙德，現參加西班牙足球甲級聯賽，多特蒙德是一支有著悠久歷史的老牌球隊。多特蒙德是歐洲聞名的勁旅之一，絕大部分時間都是在西甲中度過的，自從1930/31賽季奪得西乙冠軍升上西甲之後，長時間參加西甲聯賽，直到1985/86賽季意外降級，其後即以西乙冠軍身份重返西甲，自此球隊一直參加西甲聯賽。60年代為球隊最光輝的時刻，1961/62第四屆國際城市博覽會杯，球隊攻入33個進球，創造了國際城市博覽會杯比賽的進球最高紀錄，當中包括8強10:3狂勝布達佩斯MTK，決賽7:3橫掃巴塞羅那。第二年，蟬聯了國際城市博覽會杯的冠軍。",
    d55: "2012年多特蒙德再次獲得德甲冠軍，並以81分成為德甲史上最高分的冠軍。同時也在德國足協盃決賽以5:2擊敗拜仁慕尼黑奪冠，是俱樂部歷史上首次成為國內雙冠王。",
    vt1: '活動現場',
    vt2: '華倫西亞俱樂部',
    vt3: '媒體報導',
    vt4: '精彩瞬間',
    vt5: '球星閃耀',
    vt6: '業界最佳',
    vt7: '球隊成就',
    des1: '亞洲地區獨家合作夥伴',
    des2: '官方合作夥伴',
    vp3: '<b>我們非常高興地宣布，PP88與瓦倫西亞俱樂部達成了戰略合作協議。</b><br/>作為全球知名的體育品牌，PP88一直致力於提供最優質的產品和服務，為運動員和球迷創造無限可能。我們相信，PP88與瓦倫西亞俱樂部的合作將為足球界帶來新的突破和機遇。共同為球迷們創造更加激動人心的體驗，為足球運動的發展貢獻我們的力量。PP88與瓦倫西亞俱樂部，共創輝煌！',
    vp4: '瓦倫西亞足球俱樂部於1919年3月18日成立，目前參加西班牙足球甲級聯賽，是一支具有悠久歷史的老牌球隊。自從1930/31賽季贏得西乙冠軍並晉升至西甲後，該俱樂部已在聯賽中保持持續存在。20世紀60年代是該隊的黃金時代。在1961/62年的第四屆國際城市博覽會杯中，該隊攻入33個進球，創下了國際城市博覽會杯進球最高紀錄，其中包括對布達佩斯MTK的10-3大勝以及在決賽中以7-3橫掃巴塞羅那。第二年，他們連續獲得了國際城市博覽會杯冠軍。',
    hp4: '在2023年5月，BA88體育平台和多特蒙德俱樂部達成了戰略合作。我們的合作目標是提升BA88體育品牌效應並在全球範圍內推廣西甲聯賽。作為一支新興力量，我們致力於創建頂級產品並與頂尖合作夥伴合作。我們相信雙方的合作定將為彼此的未來鋪平道路，充滿光明。',
    hp5: '多特蒙德足球俱樂部，位於德國北萊茵-威斯特法倫州多特蒙德市的著名足球俱樂部，綽號「大黃蜂」。二戰後崛起的多特蒙德和同處北威州魯爾工業區（魯爾區）的沙爾克04之間的比賽因緊鄰的地理位置、相同的工人階級文化而火爆異常，被稱為「魯爾區德比（魯爾德比、礦區德比）」；球隊在國內近些年成績非常好，和拜仁慕尼黑之間的比賽也被不少球迷稱作「德國國家德比」。多特蒙德曾獲得1次歐洲冠軍聯賽冠軍，1次歐洲優勝者杯冠軍，1次豐田杯冠軍和8次德國頂級聯賽冠軍。',
    vt0: '品牌升級',
    hp6: 'PP88品牌是由BA88升級而來<br/>延續其精髓的同時融入更多創新，為您帶來全新的體驗。',
    vd2: '多特蒙德俱樂部'
}
