<template>
    <div class="top" :class="{noTitle:hiddenTitle}">
        <div class="back" @click="back">
            <van-icon name="arrow-left" color="var(--color-gray)"/>
        </div>
        <div class="title">{{ $t('p3') }}</div>
        <div class="tabs">
            <div class="tab" :class="{active: item.value === tabsActive}" v-for="(item, index) in tabs" :key="index"
                 @click="tabClick(item, index)">{{ item.label }}
            </div>
        </div>
    </div>
    <div style="height: 22.836vw;" v-if="!hiddenTitle"></div>
    <div style="height: 12.836vw;" v-else></div>
    <div class="content" :class="{noTitle:hiddenTitle}" @scroll="onPageScroll">
        <van-tabs v-model:active="activeTab">
            <van-tab name="vanlencia" :title="$t('h1')" class="ba-detail dortmund">
                <vanlencia-team v-if="showFirstTab" :autoplay="autoplay"/>
            </van-tab>
            <van-tab name="dortmund" :title="$t('h2')" class="ba-detail dortmund">
                <dortmud-team :show-top-swipe="showTopSwipe" :autoplay="autoplay"/>
            </van-tab>
            <van-tab name="band" :title="$t('h3')">
                <div class="ba-detail info">
                    <div class="info-block"></div>
                    <div class="info-content">
                        <div class="info-title">
                            <img class="info-title-img" v-lazy="require('@/assets/images/info/ba88_sports.png')" alt="">
                        </div>
                        <div class="info-line"></div>
                        <div class="info-item">
                            {{ $t('p4') }}
                        </div>
                        <div class="info-line"></div>
                        <div class="info-item">
                            <div class="info-item-title">{{ $t('p5') }}</div>
                            <div class="info-item-desc">
                                {{ $t('p6') }}
                            </div>
                        </div>
                        <div class="item-new-swipe">
                            <van-swipe :autoplay="3000" ref="swipe" :show-indicators="false"
                                       @change="(value)=>{return info_onChange(value)}">
                                <van-swipe-item v-for="(banner,index) in info_banner" :key="index">
                                    <img class="ba-list-swipe-item-n" v-lazy="banner.imgUrl"/>
                                </van-swipe-item>
                            </van-swipe>
                            <div class="custom-indicator-n">
                                <div @click="info_onChange(index)"
                                     :style="{backgroundColor:index == info_banner_current?'var(--color-gray_3)':'rgb(89, 159, 219,.5)'}"
                                     class="custom-indicator-n-line" v-for="(banner,index) in info_banner" :key="index">
                                </div>
                            </div>
                        </div>
                        <img class="foot-img-1" v-lazy="require('@/assets/images/info/pagcor.png')" alt="">
                        <div class="info-item foot">
                            <div class="info-item-title">{{ $t('p7') }}</div>
                            <div class="info-item-desc info-item-desc-center">{{ $t('p8') }}</div>
                            <div class="info-item-desc">{{ $t('p9') }}
                            </div>
                            <div class="info-item-desc info-item-desc-center">{{ $t('p10') }}</div>
                        </div>
                        <img class="foot-img-2" v-lazy="require('@/assets/images/info/pagcor_zs.png')" alt="">
                    </div>
                </div>
            </van-tab>
        </van-tabs>
    </div>
</template>

<script>
import 'vant/lib/index.css';
import {Icon, Swipe, SwipeItem, Tab, Tabs} from "vant";
import DortmudTeam from "@/DortmudTeam.vue";
import VanlenciaTeam from "@/VanlenciaTeam.vue";
import qs from "qs";

export default {
    components: {
        DortmudTeam,
        VanlenciaTeam,
        [Swipe.name]: Swipe,
        [SwipeItem.name]: SwipeItem,
        [Icon.name]: Icon,
        [Tabs.name]: Tabs,
        [Tab.name]: Tab,
    },
    data() {
        return {
            showFirstTab: false,
            showTopSwipe: true,
            tabs: [{label: this.$t('h1'), value: 0, subTab: 'vanlencia'}, {
                label: this.$t('h2'),
                value: 1,
                subTab: 'dortmund'
            }, {label: this.$t('h3'), value: 2, subTab: 'band'}],
            tabsActive: 0,
            stopSwipe_1: false,
            stopSwipe_3: false,
            autoplay: 3000,
            info_banner: [
                {
                    imgUrl: require('@/assets/images/info/new_1.png'),
                },
                {
                    imgUrl: require('@/assets/images/info/new_2.png'),
                },
                {
                    imgUrl: require('@/assets/images/info/new_3.png'),
                }
            ],
            info_banner_current: 0,
            activeTab: 'vanlencia',
            hiddenTitle: false,
        }
    },
    mounted() {
        let parse = qs.parse(window.location.search.substring(1));
        if (parse.app) {
            this.hiddenTitle = true;
        }
        window.addEventListener('scroll', this.handleScroll)
        var tab = this.getUrlParams3(window.location.href).tab;
        if (tab) {
            this.tabsActive = parseInt(tab);
        }
        setTimeout(() => {
            this.showFirstTab = true;
        }, 100)
    },
    methods: {
        onPageScroll(e) {
            const {scrollTop, clientHeight, scrollHeight} = e.target
            console.log(scrollTop, clientHeight, scrollHeight)
            if (scrollHeight - (scrollTop + clientHeight) < 100) {
                this.autoplay = 0;
            } else {
                this.autoplay = 3000;
            }
        },
        getUrlParams3(url) {
            // \w+ 表示匹配至少一个(数字、字母及下划线), [\u4e00-\u9fa5]+ 表示匹配至少一个中文字符
            let pattern = /(\w+|[\u4e00-\u9fa5]+)=(\w+|[\u4e00-\u9fa5]+)/ig;
            let result = {};
            url.replace(pattern, ($, $1, $2) => {
                result[$1] = $2;
            })
            return result
        },
        tabClick(item) {
            this.tabsActive = item.value;
            this.activeTab = item.subTab;
        },
        handleScroll() {
            let scrollY = document.documentElement.scrollTop;
            if (this.$refs.detailBg) {
                if (this.$refs.detailBg[1]) {
                    this.stopSwipe_1 = scrollY > (this.$refs.detailBg[1].offsetTop + this.$refs.detailBg[1].offsetHeight / 2);
                } else {
                    this.stopSwipe_1 = false
                }
                if (this.$refs.detailBg[3]) {

                    this.stopSwipe_3 = scrollY > (this.$refs.detailBg[3].offsetTop + this.$refs.detailBg[3].offsetHeight / 2);
                } else {
                    this.stopSwipe_3 = false
                }
            } else {
                this.stopSwipe_1 = false;
                this.stopSwipe_3 = false
            }
        },
        leicester_onChange(index, item, key) {
            item.current = index;
            if (key >= 0) {
                this.$refs.swipe[key].swipeTo(index)
            }
        },
        info_onChange(index) {
            this.info_banner_current = index;
        },
        back() {
            window.history.back();
        },
        toPages(path) {
            window.location.href = path
        }
    }
}
</script>
<style>
@import "@/assets/css/style.css";

:root {
    --color-black: #1b1e2f;
    --color-gray: #9faab4;
    --color-gray_2: #494b59;
    --color-gray_3: #97a7b6;
    --color-active: #d2943a;
    --color-black_2: #131524;
    --color-white: #ffffff;
    --van-tabs-nav-background: #1b1e2f;
    --van-tab-active-text-color: #d2943a;
    --van-tabs-bottom-bar-color: #d2943a;
    --van-tab-font-size: 3.3vw;
    --van-tab-text-color: #97a7b6;
    --van-tabs-bottom-bar-width: 60px;
    --van-tabs-bottom-bar-height: 2px;
}

html {
    overflow: hidden;
}

html,
body {
//font-family: 'PingfangBold'; height: 100%;
}

#app {
    height: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

@font-face {
//font-family: "PingfangBold";
//src: url("@/assets/font/PingFangBold.woff2");
}
</style>
<style scoped>
.top {
    position: fixed;
    top: 0;
    z-index: 9999;
    padding-top: 2.64vw;
    width: 100%;
    height: 22.836vw;
    background: var(--color-black);
    box-sizing: border-box;
    overflow: hidden;
}

.top.noTitle {
    padding-top: 1.64vw;
    height: 12.836vw;
}

.top.noTitle .title {
    display: none;
}

.top.noTitle .back {
    display: none;
}

.top.noTitle .tabs {
    margin-top: 1.808vw;
}

.top .title {
    color: var(--color-gray);
    font-size: 4.224vw;
    text-align: center;
}

.top .back {
    position: absolute;
    top: 3vw;
    padding-left: 4vw;
    width: 12vw;
    height: 12vw;
}

.top .tabs {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 5.808vw;
    width: 100%;
    box-sizing: border-box;
}

.top .tabs .tab {
    flex-shrink: 0;
    position: relative;
    color: var(--color-gray);
    font-size: 3.3vw;
}

.top .tabs .tab.active {
    color: var(--color-active);
    font-weight: bold;
}

.top .tabs .tab.active:after {
    content: ' ';
    position: absolute;
    bottom: -2.64vw;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%;
    height: 0.528vw;
    border-radius: 0.528vw;
    background: var(--color-active);
}

.content {
    height: calc(100% - 22.836vw);
    overflow: auto
}

.content.noTitle {
    height: calc(100% - 12.836vw);
    overflow: auto
}

.info .info-block {
    width: 100%;
    height: 11px;
    background: var(--color-black_2);
}

.info .info-content {
    background: var(--color-black);
}

.info .info-title {
    padding-top: 33px;
    padding-bottom: 13.88px;
}

.info .info-title-img {
    margin: auto;
    display: block;
    width: 43.56vw;
}

.info .info-line {
    margin: auto;
    width: 310.8px;
    height: 1px;
    background: var(--color-gray_2);
}

.info .info-item {
    padding: 18px 30px;
    color: var(--color-gray_3);
    font-size: 3.3vw;
    line-height: 1.85;
    text-indent: 8vw;
    text-align: justify;
}

.info .info-item-title {
    padding-top: 13px;
    color: var(--color-white);
    font-size: 4vw;
    text-align: center;
    text-indent: 0;
}

.info .info-item-desc {
    padding: 9px 0 0;
    color: var(--color-gray_3);
    font-size: 3.3vw;
    line-height: 1.5;
    text-indent: 8vw;
    text-align: justify;
}

.info .item-new-swipe {
    margin-top: -8px;
    padding: 0 12px;
}

.info .custom-indicator-n {
    margin-top: -8px;
}

.info .custom-indicator-n-line {
    width: 6px;
    height: 6px;
}

.info .info-item.foot {
    padding-top: 5px;
    padding-bottom: 8px;
}

.info .info-item.foot .info-item-title {
    text-align: center;
    text-indent: 0;
}

.info .info-item.foot .info-item-desc {
    line-height: 1.5;
    text-align: left;
    font-size: 3.3vw;
}

.info .info-item.foot .info-item-desc-center {
    margin-top: 8px;
    text-align: center;
    text-indent: 0;
}

.info .foot-img-1 {
    display: block;
    padding-top: 30px;
    margin: 0 auto;
    width: 50vw;
}

.info .foot-img-2 {
    display: block;
    margin: auto;
    width: 40vw;
    padding-bottom: 50px;
}

img[lazy="loading"] {
    height: 1px;
}

:deep .van-tabs__wrap {
    display: none;
}

.ba-list-swipe-item-n {
    width: 100%;
}

.item-new-bottom {
    margin-top: 8px;
    display: flex;
    justify-content: center;
}

.item-new-bottom img {
    width: 80%;
}

.custom-indicator-n {
    display: flex;
    margin: 10px 0 20px;
    align-items: center;
    justify-content: center;
}

.custom-indicator-n-line {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 0 3px
}
</style>
