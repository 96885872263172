import {createI18n} from 'vue-i18n'
import qs from "qs";
import {Locale} from "vant";
import zhCN from '@/assets/lang/zh.js'
import enUS from '@/assets/lang/en.js'
import enMS from '@/assets/lang/ms.js'
import zhTW from '@/assets/lang/tw.js'
import viVN from '@/assets/lang/vn.js'
const message = {
    zh_CN: {...zhCN},
    en_US: {...enUS},
    zh_TW: {...zhTW},
    en_MAS: {...enMS},
    vi_VN: {...viVN},
}

let parse = qs.parse(window.location.search.substring(1));
let lang = parse.lang;
if (!lang) lang = 'zh_CN';
const i18n = createI18n({
    locale: lang, // 设置语言类型
    legacy: false, // 如果要支持compositionAPI，此项必须设置为false;
    globalInjection: true, // 全局注册$t方法
    messages: message,
})

const messages = {
    'zh-CN': {
        vanPicker: {
            confirm: '关闭', // 将'确认'修改为'关闭'
        },
    },
};

Locale.add(messages);

export default i18n
